import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import BrandsBanner from "components/brandsBanner"
import ContactFormBanner from "components/contactFormBanner"
import imgBusinessProcess1 from "assets/asset-business-1-1.png"
import imgBusinessProcess2 from "assets/asset-business-1-2.png"
import imgBusinessProcess1M from "assets/asset-business-1-1-m.png"
import imgBusinessProcess2M from "assets/asset-business-1-2-m.png"
import imgPartnerTitle from "assets/asset-business-2-1.png"
import imgPartnerSlogan from "assets/asset-business-2-2.png"
import imgPartnerTitleM from "assets/asset-business-2-1-m.png"
import imgPartnerFigure from "assets/asset-partner-figure-m.png"
import imgPartnerDesc from "assets/asset-partner-desc-m.png"
import "./styles.scss"

export default () => (
  <div className="business-page-container">
    {/* BANNER 區塊 */}
    <StaticImage
      className="global-hidden-mobile"
      src="../../images/img-business-banner.png"
      alt="經營理念"
      layout="fullWidth"
      quality={100}
      breakpoints={[900, 1680, 3000]}
      placeholder="none"
      backgroundColor="#FFF"
    />
    <StaticImage
      className="global-hidden-desktop"
      src="../../images/img-business-banner-m.png"
      alt="經營理念"
      layout="fullWidth"
      quality={100}
      breakpoints={[480, 1680]}
      placeholder="none"
      backgroundColor="#FFF"
    />
    {/* 服務流程 區塊 */}
    <div className="business-process-container">
      <img className="img-business-process global-hidden-mobile" src={imgBusinessProcess1} alt="服務與流程1" />
      <img className="img-business-process global-hidden-mobile" src={imgBusinessProcess2} alt="服務與流程2" />
      <img className="img-business-process global-hidden-desktop" src={imgBusinessProcess1M} alt="服務與流程1" />
      <img className="img-business-process global-hidden-desktop" src={imgBusinessProcess2M} alt="服務與流程2" />
    </div>
    {/* 合作夥伴 區塊 */}
    <div className="partner-header">
      <img className="partner-title global-hidden-mobile" src={imgPartnerTitle} alt="Partner" />
      <img className="partner-title-m global-hidden-desktop" src={imgPartnerTitleM} alt="Partner" />
      <img className="partner-slogan" src={imgPartnerSlogan} alt="合作夥伴" />
    </div>
    <div className="partner-container">
      <div className="partner-body">
        <StaticImage
          className="partner-banner global-hidden-mobile"
          src="../../images/img-partner.png"
          alt=""
          layout="fullWidth"
          quality={100}
        />
        <img className="partner-figure global-hidden-desktop" src={imgPartnerFigure} alt="Partner" />
        <img className="partner-desc global-hidden-desktop" src={imgPartnerDesc} alt="Partner" />
      </div>
    </div>
    {/* 品牌總覽 區塊 */}
    <BrandsBanner />
    {/* 聯絡我們 區塊 */}
    <ContactFormBanner />
  </div>
)
