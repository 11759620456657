import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import BusinessPage from "pages/business"

export const Head = () => (
  <SEO
    title="經營理念"
    description="迄今已與上千家業者合作，專注於提供優質且價格合理的產品，讓台灣消費者能輕鬆購買到高品質的空白成衣。"
    pathname="/business"
  />
)

export default () => (
  <Layout>
    <BusinessPage />
  </Layout>
)
